// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/slick-carousel/slick/slick.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/slick-carousel/slick/slick-theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



:root {
  --pure-white: #ffffff;
  --dark-night: #221737;
  --midnight-indigo: #2C1973;
  --royal-lilac: #4B3EAA;
  --sunset-orange: #FF8244;

  --lavender-sky: #8680E5;
  --misty-morning: #9F99A4;
  --flaming-coral: #FF4242;
  --emerald-meadow: #0C9000;
  --honey-glow: #FFC600;

  --font-family: "Jura", sans-serif;
  --heading-font-family: "Kadwa", serif;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;;;AAIA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,0BAA0B;EAC1B,sBAAsB;EACtB,wBAAwB;;EAExB,uBAAuB;EACvB,wBAAwB;EACxB,wBAAwB;EACxB,yBAAyB;EACzB,qBAAqB;;EAErB,iCAAiC;EACjC,qCAAqC;AACvC","sourcesContent":["@import \"~slick-carousel/slick/slick.css\";\n@import \"~slick-carousel/slick/slick-theme.css\";\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n\n\n:root {\n  --pure-white: #ffffff;\n  --dark-night: #221737;\n  --midnight-indigo: #2C1973;\n  --royal-lilac: #4B3EAA;\n  --sunset-orange: #FF8244;\n\n  --lavender-sky: #8680E5;\n  --misty-morning: #9F99A4;\n  --flaming-coral: #FF4242;\n  --emerald-meadow: #0C9000;\n  --honey-glow: #FFC600;\n\n  --font-family: \"Jura\", sans-serif;\n  --heading-font-family: \"Kadwa\", serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
