import React from 'react';

const YouTubeSVG = ({ color }) => {
    return (
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={{ transition: 'fill 250ms ease-in-out' }} fillRule="evenodd" clipRule="evenodd" d="M24 0C10.7452 0 0 10.8817 0 24.3049C0 37.7281 10.7452 48.6097 24 48.6097C37.2548 48.6097 48 37.7281 48 24.3049C48 10.8817 37.2548 0 24 0ZM34.0016 15.9494C35.1031 16.2555 35.9706 17.1574 36.265 18.3028C36.8 20.3787 36.8 24.7099 36.8 24.7099C36.8 24.7099 36.8 29.0411 36.265 31.1171C35.9706 32.2625 35.1031 33.1644 34.0016 33.4707C32.0054 34.0268 24 34.0268 24 34.0268C24 34.0268 15.9946 34.0268 13.9983 33.4707C12.8967 33.1644 12.0292 32.2625 11.7348 31.1171C11.2 29.0411 11.2 24.7099 11.2 24.7099C11.2 24.7099 11.2 20.3787 11.7348 18.3028C12.0292 17.1574 12.8967 16.2555 13.9983 15.9494C15.9946 15.3931 24 15.3931 24 15.3931C24 15.3931 32.0054 15.3931 34.0016 15.9494Z" fill={color}/>
            <path style={{ transition: 'fill 250ms ease-in-out' }} d="M21.5996 29.1658V21.0642L27.9996 25.1152L21.5996 29.1658Z" fill={color}/>
        </svg>
    );
};

export default YouTubeSVG;