import React from 'react';

const VkSVG = ({ color }) => {
    return (
        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={{ transition: 'fill 250ms ease-in-out' }} fillRule="evenodd" clipRule="evenodd" d="M32.2681 0.898621H17.5327C4.08458 0.898621 0.900391 4.12326 0.900391 17.7422V32.6648C0.900391 46.2837 4.08458 49.5084 17.5327 49.5084H32.2681C45.7162 49.5084 48.9004 46.2837 48.9004 32.6648V17.7422C48.9004 4.12326 45.7162 0.898621 32.2681 0.898621ZM39.1446 16.3359C40.1947 16.3359 40.4318 16.8847 40.1947 17.6394C39.8246 19.3691 36.7516 23.9572 35.7998 25.3783C35.6189 25.6483 35.5146 25.804 35.52 25.804C35.1474 26.4214 35.0119 26.6959 35.52 27.382C35.7037 27.6356 36.0932 28.0227 36.5384 28.4651C36.9962 28.92 37.5129 29.4335 37.9251 29.9205C39.4156 31.6358 40.5673 33.0766 40.8722 34.0714C41.1432 35.0662 40.6689 35.5808 39.6527 35.5808H36.1637C35.239 35.5808 34.7624 35.043 33.7466 33.897C33.3111 33.4056 32.7764 32.8024 32.0649 32.0817C29.9985 30.0577 29.0839 29.7833 28.5758 29.7833C27.8644 29.7833 27.6612 29.9548 27.6612 30.984V34.1743C27.6612 35.0319 27.3902 35.5465 25.1545 35.5465C21.4622 35.5465 17.3634 33.2824 14.484 29.0629C10.1481 22.8881 8.96252 18.2226 8.96252 17.2964C8.96252 16.7818 9.1319 16.3016 10.1481 16.3016H13.6711C14.5518 16.3016 14.8905 16.6789 15.2293 17.6737C16.9569 22.7165 19.8362 27.1418 21.0218 27.1418C21.4622 27.1418 21.6654 26.936 21.6654 25.804V20.5896C21.5818 19.1075 21.0723 18.4618 20.6945 17.9829C20.4602 17.6859 20.2766 17.4532 20.2766 17.1249C20.2766 16.7132 20.6153 16.3016 21.1573 16.3016H26.645C27.3902 16.3016 27.6612 16.7132 27.6612 17.6051V24.6376C27.6612 25.3923 27.9661 25.6667 28.2032 25.6667C28.6435 25.6667 29.0162 25.3923 29.8291 24.569C32.3358 21.7217 34.1312 17.3307 34.1312 17.3307C34.3683 16.8161 34.7748 16.3359 35.6555 16.3359H39.1446Z" fill={color}/>
        </svg>
    );
};

export default VkSVG;